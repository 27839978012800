const api = require("./api")
const { postRequest } = require("@/api/modules/request")

module.exports = {
	matterList: function (param) {
		return new Promise(function (resolve, reject) {
			postRequest(api.matterList, param, "GET")
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	matterGet: function (param) {
		return new Promise(function (resolve, reject) {
			postRequest(api.matterGet, param, "GET")
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	matterStore: function (param) {
		return new Promise(function (resolve, reject) {
			postRequest(api.matterStore, param, "PUT")
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	matterDelete: function (param) {
		return new Promise(function (resolve, reject) {
			postRequest(api.matterDelete, param, "DELETE")
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	dealerCategoryList: function (param) {
		return new Promise(function (resolve, reject) {
			postRequest(api.dealerCategoryList, param, "GET")
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	dealerCategoryGet: function (param) {
		return new Promise(function (resolve, reject) {
			postRequest(api.dealerCategoryGet, param, "GET")
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	dealerCategoryStore: function (param) {
		return new Promise(function (resolve, reject) {
			postRequest(api.dealerCategoryStore, param, "PUT")
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	dealerCategoryDelete: function (param) {
		return new Promise(function (resolve, reject) {
			postRequest(api.dealerCategoryDelete, param, "DELETE")
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},

	newsList: function (param) {
		return new Promise(function (resolve, reject) {
			postRequest(api.newsList, param, "GET")
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	newsGet: function (param) {
		return new Promise(function (resolve, reject) {
			postRequest(api.newsGet, param, "GET")
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	newsStore: function (param) {
		return new Promise(function (resolve, reject) {
			postRequest(api.newsStore, param, "PUT")
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	newsDelete: function (param) {
		return new Promise(function (resolve, reject) {
			postRequest(api.newsDelete, param, "DELETE")
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},

	dealerEquipList: function (param) {
		return new Promise(function (resolve, reject) {
			postRequest(api.dealerEquipList, param, "GET")
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	dealerEquipGet: function (param) {
		return new Promise(function (resolve, reject) {
			postRequest(api.dealerEquipGet, param, "GET")
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	dealerEquipStore: function (param) {
		return new Promise(function (resolve, reject) {
			postRequest(api.dealerEquipStore, param, "PUT")
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	dealerEquipDelete: function (param) {
		return new Promise(function (resolve, reject) {
			postRequest(api.dealerEquipDelete, param, "DELETE")
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},

	adminUpload: function (param) {
		return new Promise(function (resolve, reject) {
			postRequest(api.adminUpload, param, "POST", "multipart/form-data")
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
}

<template>
  <div class="box">
    <div class="center">
      <div class="left">
        <img
          class="img1"
          src="https://image.taoqiandao.com/logo180px.png"
          alt=""
        />
      </div>
      <div class="right">
        <h2>淘钱岛运营管理后台</h2>
        <div class="inp1">
          <span>账户：</span
          ><input
            v-model="login.userName"
            v-text="login.userName"
            type="text"
          />
        </div>
        <div class="inp2">
          <span>密码：</span
          ><input
            v-model="login.passWord"
            v-text="login.passWord"
            type="password"
          />
        </div>
        <div class="memory">
          <el-checkbox v-model="checked">记住密码</el-checkbox>
        </div>
        <div class="btn"><button @click="loginIn()">登录</button></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "LoginPage",
  data() {
    return {
      checked: false,
      login: {
        userName: "",
        passWord: "",
      },
    };
  },
  methods: {
    //用户登录
    loginIn() {
      console.log("登录");
      var _this = this;
      if (_this.login.userName != "" && _this.login.passWord != "") {
        this.$https
          .post("/admin/login", {
            username: _this.login.userName,
            password: _this.login.passWord,
          })
          .then((res) => {
            console.log(res);
            _this.$message({
              type: "success",
              message: "登录成功!",
              duration: "1000",
            });
            // 判断是否记住密码
            if (_this.checked) {
              _this.$store.commit(
                "changeLoginFrom",
                JSON.stringify(_this.login)
              );
            } else {
              _this.$store.commit("changeLoginFrom", JSON.stringify({}));
            }
            //保存token
            _this.$store.commit("changeToken", res.access_token);
            _this.$store.commit("changeTokenType", res.token_type);
            //输入框清空
            _this.login = {};
            //成功跳转后台首页
            _this.$router.push({
              path: "/mainItem/goods/GoodsList",
            });
          });
      } else {
        alert("请输入正确账号或密码！");
      }
    },
  },
  computed: {
    ...mapState(["url", "access_token", "loginFrom"]),
  },
  created() {
    // this.$https.get(this.urlElm)
    // .then(res=>{
    //   console.log(res);
    // })
  },
  mounted() {
    console.log(JSON.stringify(this.loginFrom) !== "{}");
    if (JSON.stringify(this.loginFrom) !== "{}") {
      this.checked = true;
      this.login = this.loginFrom;
    }
  },
};
</script>
<style lang="less" scoped>
.box {
  background-color: #f9f9f9;
  overflow: hidden;
  height: 920px;
  // width: 100vw;
  .center {
    border-radius: 18px;
    margin: 150px auto;
    background-color: #fff;
    width: 800px;
    height: 500px;
    overflow: hidden;
    text-align: center;
    .left {
      float: left;
      width: 30%;
      text-align: right;
      .img1 {
        width: 100px;
        height: 100px;
        margin-top: 200px;
      }
    }
    .right {
      h2 {
        margin-bottom: 25px;
      }
      margin-top: 130px;
      float: right;
      width: 70%;
      text-align: center;
      .inp1,
      .inp2 {
        margin-bottom: 15px;
        span {
          font-weight: bold;
          font-size: 15px;
          margin-right: 10px;
        }
        input {
          background-color: #f2f2f2;
          border: none;
          outline: none;
          height: 30px;
          width: 200px;
        }
      }
      .memory {
        margin-left: 206px;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        font-size: 12px;
        input {
          width: 10px;
        }
      }
      .btn {
        button {
          width: 200px;
          height: 32px;
          line-height: 32px;
          margin-left: 50px;
          border: none;
          background-color: #f2f2f2;
          border-radius: 20px;
        }
      }
    }
  }
}
</style>

import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
// 路由
import router from "./router";
// 全局状态值
import store from "./store";
// 网络请求
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios); // 安装插件
// element-ui插件
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
import '@wangeditor/editor/dist/css/style.css'
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
Vue.use(mavonEditor)
import http from "./network/http.js";
Vue.prototype.$https = http;
Vue.config.productionTip = false;
import API from "@/api/index.js";
// 全局挂载网络请求，以供所有组件访问
Vue.prototype.$API = API;

import CKEditor from '@ckeditor/ckeditor5-vue2'
Vue.use( CKEditor )
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

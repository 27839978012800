export default {
  //接口地址
  url: "https://www.taoqiandao.com",
  urlElm: "https://elm.cangdu.org/v1/cities?type=guess",
  //账号密码
  loginFrom: [],
  //登录token
  access_token: "",
  token_type: "",
  //页面加载
  isShow: false,
};

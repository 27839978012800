import Vue from "vue"
import VueRouter from "vue-router"
//登录页面
import LoginPage from "../views/LoginPage"
//渲染首页
import HomePage from "../views/HomePage"

Vue.use(VueRouter)

const routes = [
	{
		path: "/",
		name: "LoginPage",
		component: LoginPage,
	},
	{
		path: "/LoginPage",
		name: "LoginPage",
		component: LoginPage,
	},
	{
		path: "/home",
		name: "home",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ "../views/HomePage.vue"),
	},
	{
		path: "/mainItem/goods",
		component: HomePage,
		children: [
			{
				path: "/mainItem/goods/GoodsList", //商品路由
				component: () => import("../mainItem/goods/GoodsList"),
			},
			{
				path: "/mainItem/goods/Discount", //优惠券路由
				component: () => import("../mainItem/goods/Discount"),
			},
			{
				path: "/mainItem/goods/CommodityEditor", //商品设置路由
				component: () => import("../mainItem/goods/CommodityEditor"),
			},
			{
				path: "/mainItem/goods/TypeList", //分类列表路由
				component: () => import("../mainItem/goods/TypeList"),
			},
			{
				path: "/mainItem/goods/Label", //标签设置路由
				component: () => import("../mainItem/goods/Label"),
			},
			{
				path: "/mainItem/goods/activeGoods", //活动商品
				component: () => import("../mainItem/goods/activeGoods"),
			},
			{
				path: "/mainItem/goods/CommodityEditorActive", //活动商品编辑
				component: () => import("../mainItem/goods/CommodityEditorActive"),
			},
			{
				path: "/mainItem/goods/CommodityEdit", //活动商品编辑
				component: () => import("../mainItem/goods/CommodityEdit"),
			},
		],
	},
	{
		path: "/mainItem/order",
		component: HomePage,
		children: [
			{
				path: "/mainItem/order/orderData/index", //订单路由
				component: () => import("../mainItem/order/orderData/index"),
			},
			{
				path: "/mainItem/order/orderRecords/telephone/index", //订单路由
				component: () => import("../mainItem/order/orderRecords/telephone/index"),
			},
			{
				path: "/mainItem/order/orderRecords/electricity/index", //订单路由
				component: () => import("../mainItem/order/orderRecords/electricity/index"),
			},
			{
				path: "/mainItem/order/orderRecords/oil/index", //订单路由
				component: () => import("../mainItem/order/orderRecords/oil/index"),
			},
			{
				path: "/mainItem/order/orderRecords/orderRecords/coupon/index", //订单路由
				component: () => import("../mainItem/order/orderRecords/coupon/index"),
			},
			{
				path: "/mainItem/order/OrderDetail", //优惠券路由
				component: () => import("../mainItem/order/OrderDetail"),
			},
			{
				path: "/mainItem/order/ActiveOrder", //优惠券路由
				component: () => import("../mainItem/order/ActiveOrder"),
			},
		],
	},
	// 运营
	{
		path: "/mainItem/banber",
		component: HomePage,
		children: [
			{
				path: "/mainItem/banber/banber", //订单路由
				component: () => import("../mainItem/banber/banber"),
			},
			{
				path: "/mainItem/messagePulish/messagePulish", //订单路由
				component: () => import("../mainItem/messagePulish/messagePulish"),
			},
		],
	},
	{
		//用户信息
		path: "/mainItem/user",
		component: HomePage,
		children: [
			{
				path: "/mainItem/user/UserList", //用户列表
				component: () => import("../mainItem/user/UserList"),
			},
			{
				path: "/mainItem/user/UserInvite", //用户邀请
				component: () => import("../mainItem/user/UserInvite"),
			},
			{
				path: "/mainItem/user/RealNameAudit", //实名审核
				component: () => import("../mainItem/user/RealNameAudit"),
			},
		],
	},
	// {
	// 	//活动商品
	// 	path: "/mainItem/activeGoods",
	// 	component: HomePage,
	// 	children: [
	// 		{
	// 			path: "/mainItem/activeGoods/activeGoods", //活动商品
	// 			component: () => import("../mainItem/activeGoods/activeGoods"),
	// 		},
	// 	],
	// },
	{
		//账号与安全
		path: "/mainItem/news",
		component: HomePage,
		children: [
			{
				path: "/mainItem/news/news", //修改密码
				component: () => import("../mainItem/news/news"),
			},
		],
	},
	{
		//海报信息
		path: "/mainItem/poster",
		component: HomePage,
		children: [
			{
				path: "/mainItem/poster/PosterList", //海报列表
				component: () => import("../mainItem/poster/PostList"),
			},
			{
				path: "/mainItem/poster/PosterAdd", //海报添加
				component: () => import("../mainItem/poster/PostAdd"),
			},
		],
	},
	{
		//财务信息
		path: "/mainItem/finance",
		component: HomePage,
		children: [
			{
				path: "/mainItem/finance/Withdrawal", //提现记录
				component: () => import("../mainItem/finance/Withdrawal"),
			},
			{
				path: "/mainItem/finance/Recharge", //充值记录
				component: () => import("../mainItem/finance/Recharge"),
			},
		],
	},
	{
		//消息
		path: "/mainItem/message",
		component: HomePage,
		children: [
			{
				path: "/mainItem/message/MessageList", //消息列表
				component: () => import("../mainItem/message/MessageList"),
			},
			{
				path: "/mainItem/message/Release", //消息发布
				component: () => import("../mainItem/message/Release"),
			},
		],
	},
	{
		//渠道
		path: "/mainItem/channel",
		component: HomePage,
		children: [
			{
				path: "/mainItem/channel/ChannelBusiness", //渠道商
				component: () => import("../mainItem/channel/ChannelBusiness"),
			},
			{
				path: "/mainItem/channel/Supplier", //供应商
				component: () => import("../mainItem/channel/Supplier"),
			},
			{
				path: "/mainItem/channel/Service", //服务商
				component: () => import("../mainItem/channel/Service"),
			},
		],
	},
	{
		//文档撰写
		path: "/mainItem/material",
		component: HomePage,
		children: [
			{
				path: "/mainItem/material/EarthPushing",
				component: () => import("../mainItem/material/EarthPushing"),
			},
			{
				path: "/mainItem/material/EarthPushingCategory",
				component: () => import("../mainItem/material/EarthPushingCategory"),
			},
			{
				path: "/mainItem/material/Propaganda",
				component: () => import("../mainItem/material/Propaganda"),
			},
			{
				path: "/mainItem/material/Website",
				component: () => import("../mainItem/material/Website"),
			},
		],
	},
	{
		//目标发布
		path: "/mainItem/targetPush",
		component: HomePage,
		children: [
			{
				path: "/mainItem/targetPush/signUp",
				component: () => import("../mainItem/targetPush/signUp"),
			},
			{
				path: "/mainItem/targetPush/pushList",
				component: () => import("../mainItem/targetPush/pushList"),
			},
			{
				path: "/mainItem/targetPush/cat",
				component: () => import("../mainItem/targetPush/cat"),
			}
		],
	},
	{
		//云选
		path: "/mainItem/selective",
		component: HomePage,
		children: [
			{
				path: "/mainItem/selective/list",
				component: () => import("../mainItem/selective/list"),
			}
		],
	},
	{
		//有活发布
		path: "/mainItem/lifePush",
		component: HomePage,
		children: [
			{
				path: "/mainItem/lifePush/management",
				component: () => import("../mainItem/lifePush/management"),
			},
			{
				path: "/mainItem/lifePush/numbers",
				component: () => import("../mainItem/lifePush/numbers"),
			},
			{
				path: "/mainItem/lifePush/list",
				component: () => import("../mainItem/lifePush/list"),
			}
		],
	},
]

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes,
})

export default router

import Vue from "vue";
import Vuex from "vuex";
import state from "./state";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

//引入对store存储器中state状态值做数据持久化的第三方插件库
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  //vuex数据持久化配置
  plugins: [
    createPersistedState({
      //存储方式：localStorage/sessionStorage/cookies
      storage: window.localStorage,
      //存储的key的key值
      key: "store",
      render(state) {
        //接收state对象
        //要存储的数据：本项目采用es6扩展运算符的方式存储了state中的所有数据
        return { ...state };
      },
    }),
  ],
});

module.exports = {
  //后台的物料接口
  matterList: "/admin/matter/list",
  matterGet: "/admin/matter/get",
  matterStore: "/admin/matter/store",
  matterDelete: "/admin/matter/delete",

  //地推物料分类
  dealerCategoryList: "/admin/dealer_category/list",
  dealerCategoryGet: "/admin/dealer_category/get",
  dealerCategoryStore: "/admin/dealer_category/store",
  dealerCategoryDelete: "/admin/dealer_category/delete",

  //地推装备分类
  dealerEquipList: "/admin/dealer_equip/list",
  dealerEquipGet: "/admin/dealer_equip/get",
  dealerEquipStore: "/admin/dealer_equip/store",
  dealerEquipDelete: "/admin/dealer_equip/delete",

  //官网相关
  newsList: "/admin/news/list",
  newsGet: "/admin/news/get",
  newsStore: "/admin/news/store",
  newsDelete: "/admin/news/delete",

  //上传图片
  adminUpload: "/admin/upload/goods",
};

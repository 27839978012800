import axios from "axios";
import store from "@/store/index.js";
import router from "../router/index";
import { BaseUrl as baseURL } from "@/api/setting.js";
import { Message } from "element-ui"; //ElementUI 消息提示组件Message
const http = {};

//axios.create()是添加了自定义配置的新的axios
var instance = axios.create({
  timeout: 500000000000000,
  // baseURL
});

instance.interceptors.request.use(
  (config) => {
    // console.log(store.state.access_token)
    if (store.state.access_token) {
      // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.Authorization =
        store.state.token_type + " " + store.state.access_token;
      //   console.log('interceptors config=',config)
      ;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器即异常处理
instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (err) => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = "请求出错";
          break;
        case 401: {
          Message.warning({
            message: "授权失败，请重新登录",
          });
          router.push({
            path: "/LoginPage",
          });
          return;
        }

        case 403:
          err.message = "拒绝访问";
          break;
        case 404:
          err.message = "请求错误,未找到该资源";
          break;
        case 500:
          err.message = "服务器端出错";
          break;
      }
    } else {
      err.message = "连接服务器失败";
    }
    Message.error({
      message: err.message,
    });
    return Promise.reject(err.response);
  }
);

http.get = function (url, options) {
  return new Promise((resolve, reject) => {
    instance
      .get(baseURL + url, options)
      .then((response) => {
        if (!response.status) {
          resolve(response);
        } else {
          Message.error({
            message: response.data.message,
          });
          reject(response);
          console.log(response);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  });
};
http._get = function (url, data, options) {
  return new Promise((resolve, reject) => {
    instance
      .get(baseURL + url, { params: data }, options)
      .then((response) => {
        if (!response.status) {
          resolve(response);
        } else {
          Message.error({
            message: response.data.message,
          });
          reject(response);
          console.log(response);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  });
};
http.post = function (url, data, options) {
  return new Promise((resolve, reject) => {
    instance
      .post(baseURL + url, data, options)
      .then((response) => {
        if (!response.status) {
          resolve(response);
        } else {
          Message.error({
            message: response.data.message,
          });
          reject(response);
          console.log(response);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  });
};
http.put = function (url, data, options) {
  return new Promise((resolve, reject) => {
    instance
      .put(baseURL + url, data, options)
      .then((response) => {
        if (!response.status) {
          resolve(response);
        } else {
          Message.error({
            message: response.data.message,
          });
          reject(response);
          console.log(response);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  });
};
export default http;
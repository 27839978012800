/**
 * Notice
 * @author lwd
 * @description 遍历modules下所有的业务相关index.js文件,assign无法发现重复属性报错
 * @Date 2022-06-07
 * 教训 assign浅拷贝
 */
const req = require.context("./modules", true, /\index.js$/);
const modulesArr = [];
//辅助数组 找出重复的属性
let helpArr = [];
req.keys().map((path) => {
  const context = req(path).default || req(path);
  modulesArr.push(context);
  helpArr = helpArr.concat(Object.keys(context));
});
if (duplicates(helpArr).length > 0) {
  console.log("重复属性", duplicates(helpArr));
  throw new Error("重复属性");
}
let modulesTarget = {};
for (let i = 0; i < modulesArr.length; i++) {
  for (let x in modulesArr[i]) {
    modulesTarget[x] = modulesArr[i][x];
  }
}
//const modules = Object.assign({}, )
/**
 *
 * @description 找出重复元素
 */
function duplicates(arr) {
  let temp = [];
  arr.forEach((item) => {
    if (
      arr.indexOf(item) != arr.lastIndexOf(item) &&
      temp.indexOf(item) == -1
    ) {
      temp.push(item);
    }
  });
  return temp;
}
export default modulesTarget;

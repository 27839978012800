export default {
  //表单记住密码
  changeLoginFrom(state, obj) {
    state.loginFrom = JSON.parse(obj);
  },
  //用户登录token
  changeToken(state, str) {
    state.access_token = str;
  },
  //用户登录token
  changeTokenType(state, str) {
    state.token_type = str;
  },
  //页面加载
  changeShow(state, bool) {
    state.isShow = bool;
  },
};

const { service } = require("./htttp");
import store from "@/store/index.js";

export function postRequest(
  url,
  data = {},
  method = "POST",
  contentType = "application/json"
) {
  let temp = method.toLocaleLowerCase() === "get" ? "params" : "data";
  return new Promise((resolve, reject) => {
    if (contentType == "application/x-www-form-urlencoded") {
      service({
        method: method,
        url: url,
        [temp]: data,
        transformRequest: [
          function (data) {
            let ret = "";
            for (let it in data) {
              ret +=
                encodeURIComponent(it) +
                "=" +
                encodeURIComponent(data[it]) +
                "&";
            }
            ret = ret.substring(0, ret.lastIndexOf("&"));
            return ret;
          },
        ],
        headers: {
          "Content-Type": contentType,
        },
      }).then(
        (res) => {
          if (res.status == 200) {
            resolve(res.data);
          } else {
          }
        },
        (err) => {
          reject(err);
        }
      );
    } else {
      service({
        method: method,
        url: url,
        [temp]: data,
        headers: {
          "Content-Type": contentType,
        },
      }).then(
        (res) => {
          if (res.status == 200) {
            resolve(res.data);
          }
        },
        (err) => {
          reject(err);
        }
      );
    }
  });
}
